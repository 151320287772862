<template>
  <div style="padding-bottom: 0.1rem;">
    <b-card-actions
      ref="cardA"
      no-actions
      no-body
    >
      <b-card class="m-0">
        <b-row>
          <b-col cols="12">
            <b-tabs v-model="tabIndex" pills>
              <SyncingStatus />
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>
    </b-card-actions>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SyncingStatus from '@/views/lead-service-support/SyncingStatus.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardActions,
    SyncingStatus,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
}
</script>
